/* Status Page */
.page-wrapper {
    display: flex;
    align-content: space-around;
    justify-content: center;
    padding: 24px;
}

.page {
    width: 800px;
}

.chart-section {
    padding: 16px 20px;
}

.chart-section:not(:last-child) {
    border-bottom: 1px solid grey;
}

:root {
    --chart-indicator-height: 38px;
    --display-rel-0: none;
    --display-rel-1: none;
    --display-rel-2: none;
    --display-rel-3: none;
}

.page-title {
    padding: 24px;
    font-size: 24px;
    font-weight: 600;
}

.section {
    margin: 24px;
    display: flex;
    flex-direction: column;
    border: 1px solid grey;
}

.section-title {
    font-size: 24px;
}

.chart-section {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.chart-title {
    font-size: 16px;
    font-weight: 500;
}

.chart {
    display: flex;
    flex-direction: row;
    column-gap: 2px;
}

.chart-indicator {
    height: var(--chart-indicator-height);
    background-color: #dbdbdb;
    flex-grow: 1;
}

.summary-day-section {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.summary-date {
    border-bottom: 1px solid lightgray;
    padding: 4px 0;
    font-weight: 500;
}

.summary-detail {
}

/* [0, 30] days from most recent */
.rel-0 {
    display: var(--display-rel-0);
}

/* [31, 60] days from most recent */
.rel-1 {
    display: inherit;
    display: var(--display-rel-1);
}

/* [60, 90] days from most recent */
.rel-2 {
    display: var(--display-rel-2);
}

.rel-3 {
    display: var(--display-rel-3);
}

@media (min-width: 1px) {
    :root {
        --display-rel-0: block;
    }
}

@media (min-width: 720px) {
    :root {
        --display-rel-0: inherit;
        --display-rel-1: inherit;
    }
}

@media (min-width: 900px) {
    :root {
        --display-rel-0: inherit;
        --display-rel-1: inherit;
        --display-rel-2: inherit;
    }
}

@media (min-width: 1200px) {
    :root {
        --display-rel-0: inherit;
        --display-rel-1: inherit;
        --display-rel-2: inherit;
        --display-rel-3: inherit;
    }
}

.spacer {
    flex-grow: 2;
    border-bottom: 1px solid grey;
    margin: 0 20px;
    transform: translateY(-40%);
}

.left-data {
    text-align: left;
}

.center-data {
    text-align: center;
}

.right-data {
    text-align: right;
}

.status-ok {
    background-color: #4caf50;
}

.status-info {
    background-color: #2196f3;
}

.status-warning {
    background-color: #FFC107;
}

.status-major {
    background-color: #D32F2F;
}

.chart-metrics {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
}

.chart-indicator-tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(
        calc(-50% + 9px),
        calc(var(--chart-indicator-height) + 9px)
    );
    border-radius: 4px;
    padding: 16px;
    width: 180px;
    z-index: 1000;
    font-size: 14px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 120px;
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.15));
    white-space: nowrap;
}

.tooltip-date {
    padding-bottom: 8px;
    font-weight: 500;
}

.triangle {
    width: 0;
    height: 0;
    border: solid 9px;
    border-color: transparent transparent white transparent;
    position: absolute;
    z-index: 1001;
    left: 0;
    top: 0;
    transform: translate(91px, -17px);
}

.triangle-bg {
    width: 0;
    height: 0;
    border: solid 10px;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(90px, -20px);
}

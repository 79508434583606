:root {
    --cell-border: 0.75px solid black;
}

.game-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 36px;
}

:root {
    --month-columns: 3;
}

@media (max-width: 1280px) {
    :root {
        --month-columns: 1;
    }
}

.queens-list {
    padding: 12px;
    display: grid;
    grid-template-columns: repeat(var(--month-columns), 1fr);
    column-gap: 36px;
}

.game-title {
    display: flex;
}

.game-option {
    border: 2px solid black;
    padding: 12px 12px;
    border-radius: 12px;
    margin: 8px;
}

.nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-button {
    padding: 12px 12px;
    border: 2px solid black;
    margin: 0 10px;
    border-radius: 5px;
}

.grid-container {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.grid {
    --cell-size: calc(min(90vw / var(--length), 50px));
    background-color: black;
    font-size: calc(var(--cell-size) / 2);
    font-family: sans-serif;
    display: grid;
    grid-template-columns: repeat(var(--length), var(--cell-size));
    grid-template-rows: repeat(var(--length), var(--cell-size));
    column-gap: 2px;
    row-gap: 2px;
    border: 4px solid black;
    border-radius: 4px;
}

@keyframes grow-shrink {
    0% {
        font-size: calc(var(--cell-size) / 2);
    }

    50% {
        font-size: calc(var(--cell-size) / 1.75);
    }

    100% {
        font-size: calc(var(--cell-size) / 2);
    }
}

.cell {
    background-color: var(--cell-color);
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border-width 2s ease 0s;
}

.ruled>* {
    opacity: 100%;
    transition: opacity 0.5s ease 1s;
}

.cell-win.ruled>* {
    opacity: 0%;
}

.queen>* {
    transition:
        filter 2s ease 3s,
        transform 2s ease 5s;
}

.cell-win.queen>* {
    animation: grow-shrink 1s ease-in 1s 2 alternate;
    transform: translateY(calc(-1 * var(--num-row) * (var(--cell-size) + 2px)));
}

.invalid-cell {
    --invalid-stripe: rgba(255, 10, 10, 0.7);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: repeating-linear-gradient(45deg,
            var(--invalid-stripe),
            var(--invalid-stripe) 4px,
            var(--cell-color) 4px,
            var(--cell-color) 8px);
}

.row-0 {
    --num-row: 0;
}

.row-1 {
    --num-row: 1;
}

.row-2 {
    --num-row: 2;
}

.row-3 {
    --num-row: 3;
}

.row-4 {
    --num-row: 4;
}

.row-5 {
    --num-row: 5;
}

.row-6 {
    --num-row: 6;
}

.row-7 {
    --num-row: 7;
}

.row-8 {
    --num-row: 8;
}

.row-9 {
    --num-row: 9;
}

.row-10 {
    --num-row: 10;
}

.row-11 {
    --num-row: 11;
}

.row-12 {
    --num-row: 12;
}

:root {
    --cell-color: #ffe04b;
    --cell-color: #c387e0;
    --cell-color: #729aec;
    --cell-color: #8acc6d;
    --cell-color: #ff93f3;
    --cell-color: #62efea;
    --cell-color: #a3d2d8;
    --cell-color: #dfa0bf;
    --cell-color: #b9b29e;
    --cell-color: #e6f388;
    --cell-color: #ff7b60;
    --cell-color: #dfdfdf;
    --cell-color: #b3dfa0;
    --cell-color: #96beff;
    --cell-color: #ffc992;
    --cell-color: #bba3e2;

}

.color-0 {
    --cell-color: #bba3e2;
}

.color-1 {
    --cell-color: #ffc992;
}

.color-2 {
    --cell-color: #96beff;
}

.color-3 {
    --cell-color: #b3dfa0;
}

.color-4 {
    --cell-color: #dfdfdf;
}

.color-5 {
    --cell-color: #ff7b60;
}

.color-6 {
    --cell-color: #e6f388;
}

.color-7 {
    --cell-color: #b9b29e;
}

.color-8 {
    --cell-color: #dfa0bf;
}

.color-9 {
    --cell-color: #a3d2d8;
}

.color-10 {
    --cell-color: #62efea;
}

.color-11 {
    --cell-color: #ff93f3;
}

.color-12 {
    --cell-color: #8acc6d;
}

.color-13 {
    --cell-color: #729aec;
}

.color-14 {
    --cell-color: #c387e0;
}

.color-15 {
    --cell-color: #ffe04b;
}

.color-20 {
    --cell-color: #646464;
}

.border-left {
    border-left: var(--cell-border);
}

.border-right {
    border-right: var(--cell-border);
}

.border-top {
    border-top: var(--cell-border);
}

.border-bottom {
    border-bottom: var(--cell-border);
}

.calendar-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.calendar-title {
    text-align: center;
    padding: 18px 12px;
    font-weight: 500;
}

.calendar-view {
    --num-weeks: 4;
    display: grid;
    grid-template-columns: repeat(7, 35px);
    grid-template-rows: repeat(calc(var(--num-weeks) + 1), 35px);
    row-gap: 4px;
    column-gap: 4px;
}

.date {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
}

.has-board {
    background-color: rgba(255, 215, 0, 0.7);
}

.today {
    border: 2px solid rgba(255, 20, 0, 0.7);
}

.has-board>a:hover {
    color: white;
}

.weekday-title {
    color: rgba(0, 0, 0, 0.6);
}

.weeks-4 {
    --num-weeks: 4;
}

.weeks-5 {
    --num-weeks: 5;
}

.weeks-6 {
    --num-weeks: 6;
}

:root {
    --title-pixel-side-count: 6;
    --title-pixel-size: 10px;
}

.grid-game-title {
    display: grid;
    grid-template-columns: repeat(var(--title-pixel-side-count),
            var(--title-pixel-size));
    grid-template-rows: var(--title-pixel-side-count);
    gap: 1px;
    padding: 0 4px;
}

.title-cell {
    width: var(--title-pixel-size);
    height: var(--title-pixel-size);
}
:root {
    --content-wrapper-padding: 120px 120px;
    --title-size: 50px;
    --body-column-count: 4;
    --body-column-size: 300px;
    --base-font-size: 16px;
    --base-line-height: 1.5;
    --base-text-color: #111;
    --base-background-color: #fdfdfd;
    --base-highlight-color: #ffd700;
}

@media (max-width: 800px) {
    :root {
        --content-wrapper-padding: 20px 20px;
        --title-size: 32px;
        --body-column-count: 2;
        --body-column-size: 1fr;
    }
}

/** Reset some basic elements */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
    margin: 0;
    padding: 0;
}

p {
    margin-bottom: 20px;
}

/** General */
a {
    color: var(--base-text-color);
    text-decoration: none;
    font-weight: 500;
    transition: color 100ms ease-in 0ms;
}

a:hover {
    color: var(--base-highlight-color);
}

body {
    font:
        var(--base-font-size) / var(--base-line-height) 'Roboto Mono',
        sans-serif;
    color: var(--base-text-color);
    background-color: var(--base-background-color);
}

body ::selection {
    background: var(--base-highlight-color);
}

/* Home Page */
.content-wrapper {
    display: grid;
    grid-template-columns: repeat(
        var(--body-column-count),
        var(--body-column-size)
    );
    padding: var(--content-wrapper-padding);
    column-gap: 20px;
    row-gap: 20px;
}

.intro {
    font-size: var(--title-size);
    font-weight: 700;
    grid-column: 1 / 4;
    align-self: end;
}

.intro-description {
    grid-column: 1 / 3;
    grid-row: 2;
    font-size: 24px;
}

.photo-essay-wrapper {
    display: flex;
    margin: 0 auto;
    max-width: 90%;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 48px;
}

.photo-essay-wrapper > p {
    color: #444;
}

.essay-title-container {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    color: white;
    width: 80%;
    padding: 20px;
    overflow: hidden;
}

:root {
    --essay-title: 2vw;
}
.essay-title {
    font-weight: 500;
    margin: 0;
    letter-spacing: 10px;
    text-transform: uppercase;
    width: 100%;
    font-size: clamp(24px, 10vw, 72px);
}

.essay-subtitle {
    font-size: 36px;
}

.photoset {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 12px;
    align-self: stretch;
    flex-wrap: wrap;
    transition: all 1s;
}

.photoset.loading {
    height: 800px;
    overflow: hidden;
}

.photoset.hidden {
    transform: translateY(20px);
    opacity: 0;
}

.cover-container {
    height: 100vh;
    width: 100%;
    padding-bottom: 72px;
}

.cover {
    width: 100%;
    position: absolute;
    height: 100%;
    object-fit: cover;
}

.photo {
    max-width: 95vw;
}

.handwave {
    animation: wave infinite 6s 2s;
    display: inline-block;
    translate: 5px -5px;
}

@keyframes wave {
    /* Beginning */
    0% {
        translate: 5px -5px;
        rotate: 0deg;
    }

    /* Apex of wave 1 */
    5% {
        translate: -5px 7px;
        rotate: -8deg;
    }

    /* End of wave 1 */
    10% {
        translate: 3px -4px;
        rotate: 2deg;
    }

    /* Apex of wave 2 */
    15% {
        translate: -5px 7px;
        rotate: -8deg;
    }

    /* End of wave 2 */
    20% {
        translate: 5px -5px;
        rotate: 2deg;
    }

    /* Delay */
    100% {
        translate: 5px -5px;
        rotate: 2deg;
    }
}

.lightbox {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 1s;
}

.lightbox .background {
    position: absolute;
    background-color: black;
    opacity: 90%;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.lightbox img {
    max-height: 90%;
    max-width: 90%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 4;
    cursor: none;
}

img.opened {
    opacity: 1;
}

.lightbox.open {
    display: flex;
    opacity: 1;
    z-index: 3;
}
